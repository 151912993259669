import React, { useState } from 'react';
import './Login.css';
import logo from '../logo.png';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import config from '../config/config';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleLogin = (event) => {
    event.preventDefault();
  
    const loginData = {
      user_name: username,
      user_password: password
    };
  
    fetch(`${config.API_URL}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(loginData)
    })
      .then(response => {
        if (!response.ok) {
          console.log('Error:', response.err);
          return response.json().then(err => { throw err; });
        }
        return response.json();
      })
      .then(data => {
        const user_id = data.user_id;
        const profileIds = data.profile_ids || [];
        const algoUser = profileIds.includes(1);
        const equityUser = profileIds.includes(2);
        const equityPremiumUser = profileIds.includes(3);
        const equityMiniUser = profileIds.includes(4);

        localStorage.setItem('token', data.token);

        toast.success('Login Successful');
        console.log('User ID:', user_id);
        
        navigate('/dashboard', {
          state: {
            userId : user_id,
            username: username,
            algoUser: algoUser,
            equityUser: equityUser,
            equityPremiumUser: equityPremiumUser,
            equityMiniUser: equityMiniUser
          }
        });
      })
      .catch((error) => {
        console.error('Error:', error);
        toast.error('Login Failed');
      });
  };
  

  return (
    <div className="Login-container">
      <img src={logo} className="App-logo" alt="logo" />
      <h2 style={{color: '#ff593f', fontWeight: 'bold'}}>Login</h2>
      <form onSubmit={handleLogin}>
        <div className="form-group">
          <label>Username </label>
          <input
            type="text"
            value={username}
            placeholder='Enter your username'
            onChange={handleUsernameChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Password</label>
          <input
            type="password"
            placeholder='Enter your password'
            value={password}
            onChange={handlePasswordChange}
            required
          />
        </div>
        <button type="submit">Login</button>
      </form>
       <a href="/forget-password">Change Password?</a>
      <ToastContainer />
    </div>
  );
}

export default Login;