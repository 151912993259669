import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card, Button, Navbar, Nav, Dropdown } from 'react-bootstrap';
import './Dashboard.css';
import Footer from './Footer';

const DashboardPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    let { username, userId } = location.state || {};
    console.log('Retrieved state:', { username, userId });
    const algoUser = location.state?.algoUser || false;
    const equityUser = location.state?.equityUser || false;
    const equityPremiumUser = location.state?.equityPremiumUser || false;
    const equityMiniUser = location.state?.equityMiniUser || false;
    
  return (
    <div id="dashboard-container">
      <div className="content-wrap">
        {/* Navbar Section */}
        <Navbar bg="dark" variant="dark" expand="lg" sticky="top">
          <Container>
            <Navbar.Brand href="/">Wynby Tech.</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto">
                {/* <Nav.Link href="/settings">Settings</Nav.Link> */}
                <Dropdown align="end">
                  <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                    {username}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item href="/forget-password">Change Password</Dropdown.Item>
                      {/* <Dropdown.Item as={Link} 
                              to={{ 
                                pathname: "/user-profile", 
                                state: { userId, username, algoUser, equityUser }
                              }}>
                        Profile
                      </Dropdown.Item> */}
                    <Dropdown.Item href="/">Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        {/* Welcome Section */}
        <section className="welcome-section py-5 text-center">
          <Container>
            <h1>Welcome, {username}!</h1>
            <p>Here’s your dashboard. Manage your account, view your stats, and access the features you need.</p>
          </Container>
        </section>

        {/* Main Content Section */}
        <Container className="py-4">
          <Row>
            {/* Algowyn Card */}
            <Col md={6}>
              <Card className="mb-4 shadow-sm bg-dark text-light">
                <Card.Body>
                  <Card.Title>AlgoWyn</Card.Title>
                  <Card.Text>
                   Explore your intraday orders easily and keep track of live strategies with real-time data.
                  </Card.Text>
                  <Button
                      variant="primary"
                      style={{ width: 'auto' }}
                      onClick={() => navigate('/orders', { state: { userId: userId, userName: username, algoUser: algoUser } })}
                  >
                      Visit AlgoWyn
                  </Button>
                </Card.Body>
              </Card>
            </Col>

            {/* EquityWyn Card */}
            <Col md={6}>
              <Card className="mb-4 shadow-sm bg-dark text-light">
                <Card.Body>
                  <Card.Title>EquityWyn</Card.Title>
                  <Card.Text>
                    Discover suggested stocks and personalized equity calls to optimize your portfolio.
                  </Card.Text>
                  <Button
                      variant="primary"
                      style={{ width: 'auto' }}
                      onClick={() => navigate('/equitywyn/equities', { state: { userId: userId, userName: username, equityUser: equityUser, equityMiniUser: equityMiniUser, equityPremiumUser: equityPremiumUser } })}
                  >
                      Visit EquityWyn
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          {/* Quick Access Links */}
          {/* <Row>
            <Col md={6}>
              <Card className="mb-4 bg-dark text-light">
                <Card.Body>
                  <Card.Title>Account Settings</Card.Title>
                  <Card.Text>
                    Manage your account settings, including password, preferences, and profile info.
                  </Card.Text>
                  <Button variant="secondary" href="/account-settings">
                    Go to Settings
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6}>
              <Card className="mb-4 bg-dark text-light">
                <Card.Body>
                  <Card.Title>Help & Support</Card.Title>
                  <Card.Text>
                    Need assistance? Visit our help center or contact support for help with your account.
                  </Card.Text>
                  <Button variant="warning" href="/support">
                    Get Support
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row> */}
        </Container>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default DashboardPage;