import React, { useState, useEffect } from 'react';
import logo from '../logo.png';
import { useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from '../config/config';
import './Registration.css';

function Registration() {
    const navigate = useNavigate();
    const location = useLocation();
    const isAdmin = location.state?.isAdmin || false;

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [strategy, setStrategy] = useState([]);

    const [strategies, setStrategies] = useState([]);
    const [plans, setPlans] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState('');
    const [activationStatus, setActivationStatus] = useState(false);
    const [selectedDate, setSelectedDate] = useState('');
    const [isUsernameAvailable, setIsUsernameAvailable] = useState(null);
    const [isNumberAvailable, setIsNumberAvailable] = useState(null);

    useEffect(() => {
        fetch(`${config.API_URL}/algoprofile`)
            .then((response) => response.json())
            .then((data) => setStrategies(data))
            .catch((error) => console.error('Error fetching strategies:', error));
    }, []);

    useEffect(() => {
        if (isAdmin) fetchPlans();
    }, [isAdmin]);

    const fetchPlans = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${config.API_URL}/get-algo-plans`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            const data = await response.json();
            setPlans(data);
        } catch (error) {
            console.error('Error fetching plans:', error);
        }
    };

    const checkAvailability = async (type, value) => {
        try {
            const response = await fetch(`${config.API_URL}/exists/${type}/${value}`);
            const data = await response.json();
            type === 'username' ? setIsUsernameAvailable(data.status === 'success') : setIsNumberAvailable(data.status === 'success');
        } catch (error) {
            console.error(`Error checking ${type} availability:`, error);
            type === 'username' ? setIsUsernameAvailable(false) : setIsNumberAvailable(false);
        }
    };

    const handleAdminRegistration = async (event) => {
        event.preventDefault();

        const selectedPlanDetails = plans.find(plan => plan.id === selectedPlan);
        const period = selectedPlanDetails?.Period || 0;

        const dueDate = new Date(selectedDate);
        dueDate.setDate(dueDate.getDate() + period);

        const adminData = {
            first_name: firstName,
            last_name: lastName,
            user_name: username,
            user_password: password,
            email_address: email,
            phone_number: phoneNumber,
            profiles: strategy,
            plan_id: selectedPlan,
            paid_date: selectedDate,
            due_date: dueDate.toISOString().split('T')[0],
            is_enabled: activationStatus,
        };

        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${config.API_URL}/register-user-as-admin`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`, },
                
                body: JSON.stringify(adminData),
            });
            if (response.ok) {
                toast.success('Admin user registered successfully');
                navigate(-1);
            } else toast.error('Admin registration failed');
        } catch (error) {
            console.error('Error registering admin:', error);
            toast.error('Registration failed');
        }
    };

    const handleRegistration = (event) => {
        event.preventDefault();

        const registrationData = {
            first_name: firstName,
            last_name: lastName,
            user_name: username,
            user_password: password,
            email_address: email,
            phone_number: phoneNumber,
            profiles: strategy,
        };

        fetch(`${config.API_URL}/register`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(registrationData),
        })
            .then((response) => {
                if (response.ok) {
                    toast.success('Registration Successful');
                    navigate('/login');
                } else {
                    toast.error('Registration Failed');
                }
            })
            .catch((error) => {
                console.error('Error registering user:', error);
                toast.error('Registration Failed');
            });
    };

    return (
        <div className="registration-container">
            <img src={logo} alt="Logo" className="App-logo" />
            <h2>{isAdmin ? 'Register User as Admin' : 'User Registration'}</h2>
            <form onSubmit={isAdmin ? handleAdminRegistration : handleRegistration}>
                <div className="form-group">
                    <input
                        type="text"
                        placeholder="First Name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        placeholder="Last Name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        placeholder="Username"
                        value={username}
                        onChange={(e) => {
                            setUsername(e.target.value);
                            if (e.target.value) checkAvailability('username', e.target.value);
                        }}
                        required
                        style={{
                            color: username.length >= 5
                                ? (isUsernameAvailable === true ? 'green' : isUsernameAvailable === false ? 'red' : 'white')
                                : 'white',
                        }}
                    />
                </div>
                <div className="form-group">
                    <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <input
                        type="password"
                        placeholder="Confirm Password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <input
                        type="tel"
                        placeholder="Phone Number"
                        value={phoneNumber}
                        onChange={(e) => {
                            setPhoneNumber(e.target.value);
                            if (e.target.value) checkAvailability('phonenumber', e.target.value);
                        }}
                        maxLength={10}
                        required
                        style={{
                            color: phoneNumber.length >= 10
                                ? (isNumberAvailable === true ? 'green' : isNumberAvailable === false ? 'red' : 'white')
                                : 'white',
                        }}
                    />
                </div>

                <div className="form-group">
                    <label>Select Profiles</label>
                    {strategies.map((strategyItem) => (
                        <div className="form-group-radio-item" key={strategyItem.id}>
                            <input
                                type="checkbox"
                                value={strategyItem.id}
                                checked={strategy.includes(strategyItem.id)}
                                onChange={() =>
                                    setStrategy((prev) =>
                                        prev.includes(strategyItem.id)
                                            ? prev.filter((id) => id !== strategyItem.id)
                                            : [...prev, strategyItem.id]
                                    )
                                }
                            />
                            {strategyItem.profile_name}
                        </div>
                    ))}
                </div>

                {isAdmin && (
                    <>
                        <div className="form-group">
                            <label>Select Plan:</label> 
                            <select
                                value={selectedPlan}
                                onChange={(e) => setSelectedPlan(e.target.value)}
                            >
                                <option value="">Select a plan</option>
                                {plans.map((plan) => (
                                    <option key={plan.id} value={plan.id}>
                                        {plan.Name} - {plan.Period} days
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Activation Date:</label>
                            <input
                                type="date"
                                value={selectedDate}
                                onChange={(e) => setSelectedDate(e.target.value)}
                                required
                            />
                        </div>
                        <div className="form-group-radio">
                            <label>
                                <input
                                    type="checkbox"
                                    checked={activationStatus}
                                    onChange={(e) => setActivationStatus(e.target.checked)}
                                />
                                Activate User
                            </label>
                        </div>
                    </>
                )}

                <button type="submit">Register</button>
            </form>
            <ToastContainer />
        </div>
    );
}

export default Registration;