import React, { useState, useCallback, useEffect } from 'react';
import './PayModal.css';
import config from '../config/config';

function PayModal({ userId, strategy, dueDate, lastPaidDate, onClose }) {
  const [paidDate, setPaidDate] = useState('');
  const [days, setDays] = useState(30);
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState('');
  
  // Fetch plans from API
  const fetchPlans = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found, please login');
      }
      const response = await fetch(`${config.API_URL}/get-algo-plans`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Error fetching plans');
      }
      const data = await response.json();
      setPlans(data);
      console.log('Plans:', data);
    } catch (error) {
      console.error('Error fetching plans:', error);
      alert('Failed to load plans. Please try again later.');
    }
  }, []);

  const handlePlanChange = (planId) => {
    setSelectedPlan(planId);
    const selected = plans.find((plan) => plan.id === parseInt(planId));
    if (selected) {
      setDays(parseInt(selected.Period));
    }
  };

  const handleSave = async () => {
    try {
      if (!paidDate || !selectedPlan) {
        alert('Please select a paid date and plan.');
        return;
      }
      let newDueDate;
      const selectedPaidDate = new Date(paidDate);
      const selectedDueDate = new Date(dueDate);

      if (selectedPaidDate <= selectedDueDate) {
        newDueDate = new Date(dueDate);
      } else {
        newDueDate = new Date(paidDate);
      }
      newDueDate.setDate(newDueDate.getDate() + days);

      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found, please login');
      }
      const response = await fetch(`${config.API_URL}/save-subscription-details`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          user_id: userId,
          paid_date: paidDate,
          strategy: selectedPlan,
          new_due_date: newDueDate.toISOString().split('T')[0],
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to save subscription details');
      }

      const data = await response.json();
      console.log('Save successful:', data);
      alert('Subscription updated successfully!');
      onClose();
    } catch (error) {
      console.error('Error saving subscription details:', error);
      alert('Failed to update subscription. Please try again.');
    }
  };

  useEffect(() => {
    fetchPlans();
  }, [fetchPlans]);

  return (
    <div className="pay-modal">
      <div className="modal-content">
        <h2>Pay for User ID {userId} on Strategy {strategy}</h2>
        <label>
          Paid Date
          <input type="date" value={paidDate} onChange={(e) => setPaidDate(e.target.value)} />
        </label>
        <label>
          Plan
          <select value={selectedPlan} onChange={(e) => handlePlanChange(e.target.value)}>
            <option value="" disabled>Select a plan</option>
            {plans.map((plan) => (
              <option key={plan.id} value={plan.id}>
                {plan.Name} - {plan.Period} days
              </option>
            ))}
          </select>
        </label>
        <div className="modal-buttons">
          <button className="save-button" onClick={handleSave}>Pay</button>
          <button className="close-button" onClick={onClose}>Cancel</button>
        </div>
      </div>
    </div>
  );
}

export default PayModal;