import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import config from '../../config/config';
import './HighMomentumStocks.css';
import HighMomentumStockDetails from './HighMomentumStockDetails';

function HighMomentumStocks() {
    const [filters, setFilters] = useState({
        date_time: '',
        rsi: 'NA',
        ema: 'NA',
        buy_count: 'NA',
        obv_count: 'NA',
        obv_date: 'NA',
    });
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();
    const [highMomentumStocks, setHighMomentumStocks] = useState([]);
    const [stockDetails, setStockDetails] = useState(null);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [stockCode, setStockCode] = useState('');

    const formatDate = (dateString) => {
        if (!dateString) return '-';
        const date = new Date(dateString);
        const day = String(date.getUTCDate()).padStart(2, '0');
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const year = String(date.getUTCFullYear()).slice(-2);
        return `${day}/${month}/${year}`;
    };

    const fetchHighMomentumStocks = useCallback(async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No token found, please login');
            }

            const queryParams = new URLSearchParams(filters).toString(); 
            const response = await fetch(`${config.API_URL}/get-highmomentum-stocks?${queryParams}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            if (!response.ok) {
                throw new Error('Error fetching stocks');
            }
            const data = await response.json();
            setHighMomentumStocks(data);
        } catch (error) {
            console.error('Error:', error);
        }
    }, [filters]);

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value,
        }));
    };

    const handleClearFilters = () => {
        const defaultFilters = {
            date_time: '',
            rsi: 'NA',
            ema: 'NA',
            buy_count: 'NA',
            obv_count: 'NA',
            obv_date: 'NA',
        };
        setFilters(defaultFilters);
        setSearchQuery('');
    };
    

    const handleBack = () => {
        navigate(-1);
    };

    const handleStockClick = (stockCode) => {
        console.log('Fetching for Stock Code:', stockCode);
        fetchStockDetails(stockCode);
        console.log('Fetched for Stock Code:', stockCode);
    };

    const fetchStockDetails = async (stockCode) => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No token found, please login');
            }
            const response = await fetch(`${config.API_URL}/get-high-momentum-stock-details/${stockCode}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            if (!response.ok) {
                throw new Error('Error fetching stock details');
            }
            const data = await response.json();
            setStockDetails(data);
            setStockCode(stockCode);
            setShowDetailsModal(true);
        } catch (error) {
            console.error('Error:', error);
            alert('Error fetching stock details');
        }
    };

    useEffect(() => {
        fetchHighMomentumStocks();
    }, [fetchHighMomentumStocks]);

    // Filter stocks based on the search query
    const filteredStocks = highMomentumStocks.filter((stock) =>
        stock.stock_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    stock.stock_desc.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="container">
            <button className="back-button-equitywyn" onClick={handleBack}>Back</button>
            <h2>High Momentum Stocks</h2>
            <div className="filters">
                <label>
                    Date
                    <input
                        type="date"
                        name="date_time"
                        value={filters.date_time}
                        onChange={handleFilterChange}
                    />
                </label>
                <label>
                    RSI
                    <select name="rsi" value={filters.rsi} onChange={handleFilterChange}>
                        <option value="NA">NA</option>
                        <option value=">30">Greater than 30</option>
                        <option value=">40">Greater than 40</option>
                        <option value=">50">Greater than 50</option>
                        <option value=">60">Greater than 60</option>
                    </select>
                </label>
                <label>
                    EMA
                    <select name="ema" value={filters.ema} onChange={handleFilterChange}>
                        <option value="NA">NA</option>
                        <option value=">close_price">Greater than Closing Price</option>
                        <option value="<close_price">Less than Closing Price</option>
                    </select>
                </label>
                <label>
                    Buy Count
                    <select name="buy_count" value={filters.buy_count} onChange={handleFilterChange}>
                        <option value="NA">NA</option>
                        <option value=">1">Greater than 1</option>
                        <option value=">2">Greater than 2</option>
                        <option value=">3">Greater than 3</option>
                        <option value=">5">Greater than 5</option>
                        <option value=">10">Greater than 10</option>
                    </select>
                </label>
                <label>
                    OBV Count:
                    <select name="obv_count" value={filters.obv_count} onChange={handleFilterChange}>
                        <option value="NA">NA</option>
                        <option value=">1">Greater than 1</option>
                        <option value=">2">Greater than 2</option>
                        <option value=">3">Greater than 3</option>
                        <option value=">5">Greater than 5</option>
                        <option value=">10">Greater than 10</option>
                    </select>

                </label>
                <label>
                  OBV  Date:
                    <input
                        type="date"
                        name="obv_date"
                        value={filters.obv_date}
                        onChange={handleFilterChange}
                    />
                </label>
                <label>
                    Search
                    <input
                    type="text"
                    className="search-box"
                    placeholder="Stock Name"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
                </label>
                
                <button className="clear-button-highmomentum" onClick={handleClearFilters}>
                    Clear Filters
                </button>
            </div>
            <div>
                <table className="high-momentum-stocks-table">
                    <thead>
                        <tr>
                            <th>Stock Name</th>
                            <th>Buy Count</th>
                            <th>Desc</th>
                            <th>EMA</th>
                            <th>RSI</th>
                            <th>Close Price</th>
                            <th>Date</th>
                            <th>OBV Count</th>
                            <th>OBV Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredStocks.map((stock) => (
                            <tr key={stock.id}>
                                <td>
                                    <span
                                        className="stock-link"
                                        onClick={() => handleStockClick(stock.stock_name)}
                                        style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                                    >
                                        {stock.stock_name}
                                    </span>
                                </td>
                                <td>{stock.buy_count}</td>
                                <td>{stock.stock_desc}</td>
                                <td>{stock.EMA}</td>
                                <td>{stock.RSI}</td>
                                <td>{stock.close_price}</td>
                                <td>{formatDate(stock.date_time)}</td>
                                <td>{stock.obv_count}</td>
                                <td>{formatDate(stock.obv_date)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {showDetailsModal && (
                <HighMomentumStockDetails
                    stockDetails={stockDetails}
                    stockCode={stockCode}
                    onClose={() => setShowDetailsModal(false)}
                />
            )}
        </div>
    );
}

export default HighMomentumStocks;