import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Home from './components/Home';
import Login from './components/Login';
import Registration from './components/Registration';
import ForgetPassword from './components/ForgetPassword';
import Orders from './components/Orders';
import Monitor from './components/Monitor';
import JobStatus from './components/JobStatus';
import Logs from './components/Logs';
import Subscription from './components/Subscription';
// import EquityWynHome from './components/equitywyn/EquityWynHome';
import EquityWynEquities from './components/equitywyn/EquityWynEquities';
import EquityWynStockDetails from './components/equitywyn/EquityWynStockDetails';
import EquityWynAllCalls from './components/equitywyn/EquityWynAllCalls';
import EquityWynWatchList from './components/equitywyn/EquityWynWatchList';
import UserProfile from './components/UserProfile';
import BrokerSettingsModal from './components/BrokerSettingsModal';
import StocksDashboard from './components/equitywyn/StocksDashboard';
import HighMomentumStocks from './components/equitywyn/HighMomentumStocks';
import ExpiryDates from './components/ExpiryDates';
import Dashboard from './components/Dashboard';

import Disclaimer from './components/Disclaimer';

function App() {
  return (
    <Router>
      <div className="App">
      <ToastContainer />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/registration" element={<Registration />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/jobStatus" element={<JobStatus />} />
          <Route path="/monitor" element={<Monitor />} />
          <Route path="/logs" element={<Logs />} />
          <Route path="/subscription" element={<Subscription />} />
          {/* <Route path="/equitywyn/home" element={<EquityWynHome />} /> */}
          <Route path="/equitywyn/equities" element={<EquityWynEquities />} />
          <Route path="/equitywyn/stock-details" element={<EquityWynStockDetails />} />
          <Route path="/equitywyn/all-calls" element={<EquityWynAllCalls />} />
          <Route path="/equitywyn/watch-list" element={<EquityWynWatchList />} />
          <Route path="/user-profile" element={<UserProfile />} />
          <Route path="/high-momentum-stocks" element={<HighMomentumStocks />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="/broker-settings" element={<BrokerSettingsModal />} />
          <Route path="/stocks-dashboard" element={<StocksDashboard />} />
          <Route path="/expiry-dates" element={<ExpiryDates />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;