import React from 'react';
import './ExpandOrderModal.css';

const ExpandModal = ({ order, onClose }) => {
  return (
    <div className="popup">
      <div className="popup-content">
        <h2>Order Details</h2>
        <table>
          <tbody>
            <tr>
              <td><strong>Scrip Name:</strong></td>
                <td>{order.scrip_name}{order.buy_order_id === "0" ? " (TEST)" : ""}</td>
            </tr>
            <tr>
              <td><strong>Buy Price:</strong></td>
              <td>{order.buy_price}</td>
            </tr>
            <tr>
              <td><strong>Actual Sell Price:</strong></td>
              <td>{order.sell_price !== null ? order.sell_price : 'N/A'}</td>
            </tr>
            <tr>
              <td><strong>Profit/Loss:</strong></td>
              <td>{order.pnl !== null ? order.pnl : 'N/A'}</td>
            </tr>
            <tr>
              <td><strong>Quantity:</strong></td>
              <td>{order.buy_qty}</td>
            </tr>
            <tr>
              <td><strong>Initial Stop Loss:</strong></td>
              <td>{order.stop_loss}</td>
            </tr>
            <tr>
              <td><strong>Last Stop Loss:</strong></td>
              <td>{order.last_stop_loss}</td>
            </tr>
            <tr>
              <td><strong>Target Price:</strong></td>
              <td>{order.target_price}</td>
            </tr>
            <tr>
              <td><strong>Target Revised:</strong></td>
              <td>{order.target_revised}</td>
            </tr>
            <tr>
              <td><strong>Target Revised Trail:</strong></td>
              <td>{order.target_revised_trail}</td>
            </tr>
            <tr>
              <td><strong>High Price:</strong></td>
              <td>{order.high_price}</td>
            </tr>
            <tr>
              <td><strong>Order Buy Time:</strong></td>
              <td>{order.order_buy_datetime}</td>
            </tr>
            <tr>
              <td><strong>Order Sell Time:</strong></td>
              <td>{order.order_sell_datetime !== null ? order.order_sell_datetime : 'N/A'}</td>
            </tr>
            <tr>
              <td><strong>Buy Order ID</strong></td>
              <td>{order.buy_order_id}</td>
            </tr>
            <tr>
              <td><strong>Sell Order ID</strong></td>
              <td>{order.SL_order_id !== null ? order.sell_order_id : 'N/A'}</td>
            </tr>
            <tr>
              <td><strong>Strategy:</strong></td>
              <td>{order.strategy_name !== null ? order.strategy_name : 'N/A'}</td>
            </tr>
            <tr>
              <td><strong>Status:</strong></td>
              <td>{order.status}</td>
            </tr>
          </tbody>
        </table>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default ExpandModal;