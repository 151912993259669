import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import config from '../config/config';
import './ExpiryDates.css';
import AddExpiryDateModal from './AddExpiryDateModal';
import { toast } from 'react-toastify';

function ExpiryDates() {

    const navigate = useNavigate();
    const [expiryDates, setExpiryDates] = useState([]);
    const [scrips, setScrips] = useState([]);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [scripName, setScripName] = useState('');
    const [showAddExpiryDateModal, setShowAddExpiryDateModal] = useState(false);

    const fetchExpiryDates = useCallback(async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No token found, please login');
            }

            const queryParams = new URLSearchParams();
            if (fromDate) queryParams.append('from_date', fromDate);
            if (toDate) queryParams.append('to_date', toDate);
            if (scripName) queryParams.append('scrip_name', scripName);

            const response = await fetch(`${config.API_URL}/get-algoexpdate?${queryParams.toString()}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Error fetching expiry dates');
            }

            const data = await response.json();
            setExpiryDates(data);
        } catch (error) {
            console.error('Error:', error);
        }
    }, [fromDate, toDate, scripName]);

    useEffect(() => {
        fetchExpiryDates();
    }, [fetchExpiryDates]);
   
    const fetchScrips = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No token found, please login');
            }
            const response = await fetch(`${config.API_URL}/get-scrips`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            if (!response.ok) {
                throw new Error('Error fetching scrips');
            }
            const data = await response.json();
            setScrips(data);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        fetchScrips();
    }, []);

    const handleAddExpiryDate = () => {
        setShowAddExpiryDateModal(true);
    };

    const handleDeleteExpiryDate = async (scripName, fromDate, toDate, expDate) => {
        const confirmDelete = window.confirm(
            `Are you sure you want to delete the expiry date for scrip: ${scripName}, exp_date: ${expDate}?`
        );
    
        if (!confirmDelete) {
            return; // If the user cancels, do nothing
        }
    
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No token found, please login');
            }
            const response = await fetch(`${config.API_URL}/delete-algoexpdate`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    scrip_name: scripName,
                    from_date: fromDate,
                    to_date: toDate,
                    exp_date: expDate,
                }),
            });
            if (!response.ok) {
                throw new Error('Error deleting expiry date');
            }
            const data = await response.json();
            console.log('Delete successful:', data);
            toast.success('Expiry date deleted successfully');
            fetchExpiryDates();
        } catch (error) {
            console.error('Error:', error);
            toast.error(`Failed: ${error.message}`);
        }
    };
    
    return (
        <div className="expiry-dates-container">
            <div className="action-buttons-expiry-dates">
                <button className='back-button-expiry-dates' onClick={() => navigate(-1)}>Back</button>
                <button className='add-expiry-date-button' onClick={handleAddExpiryDate}>Add Expiry Date</button>
            </div>
            <h2>Expiry Dates</h2>
            <div className='expiry-dates-filters'>
                <div className="date-selection">
                    <label>
                        From Date:
                        <input type="date" value={fromDate} onChange={(e) => setFromDate(e.target.value)} />
                    </label>
                </div>
                <div className="date-selection">
                    <label>
                        To Date:
                        <input type="date" value={toDate} onChange={(e) => setToDate(e.target.value)} />
                    </label>
                </div>
                <div className="scrip-name">
                    <label>
                        Scrip Name:
                        <select value={scripName} onChange={(e) => setScripName(e.target.value)}>
                            <option value="">All</option>
                            {scrips.map(([id, name]) => (
                                <option key={id} value={name}>
                                    {name}
                                </option>
                            ))}
                        </select>
                    </label>
                </div>

            </div>
            <div className='expiry-dates-table'>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Scrip Name</th>
                            <th>From Date</th>
                            <th>To Date</th>
                            <th>Expiry Date</th>
                            <th>Kite</th>
                            <th>Shoonya</th>
                            <th>Fivepaisa</th>
                            <th>Angel</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {expiryDates.map((expiryDate, index) => (
                            <tr key={index}>
                                <td>{expiryDate.scrip_name}</td>
                                <td>{expiryDate.from_date}</td>
                                <td>{expiryDate.to_date}</td>
                                <td>{expiryDate.exp_date}</td>
                                <td>{expiryDate.kite_expiry}</td>
                                <td>{expiryDate.shoonya_expiry}</td>
                                <td>{expiryDate.fivepaisa_expiry}</td>
                                <td>{expiryDate.angel_expiry}</td>
                                <td>
                                    <button className='delete-button' onClick={() => handleDeleteExpiryDate(expiryDate.scrip_name, expiryDate.from_date, expiryDate.to_date, expiryDate.exp_date)}>Delete</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {showAddExpiryDateModal && (
                <AddExpiryDateModal
                    onClose={() => setShowAddExpiryDateModal(false)}
                />
            )}
        </div>
    );
}

export default ExpiryDates;