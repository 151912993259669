import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import config from '../config/config';
import './UserConfigModal.css';

function UserConfigModal({ isAdmin, userName, onClose }) {
    const [usersConfig, setUsersConfig] = useState([]);
    const [selectedUser, setSelectedUser] = useState('');
    const [newConfig, setNewConfig] = useState(null);

    const fetchUsersConfig = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) throw new Error('No token found, please login');
            const response = await fetch(`${config.API_URL}/get-users-config`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            if (!response.ok) throw new Error('Error fetching user config');
            const data = await response.json();
            setUsersConfig(data);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        fetchUsersConfig();
    }, []);

    useEffect(() => {
        if (!isAdmin) setSelectedUser(userName);
    }, [isAdmin, userName]);

    const handleInputChange = (value, field, id) => {
        if (id === 'new') {
            setNewConfig((prev) => ({ ...prev, [field]: value }));
        } else {
            setUsersConfig((prev) =>
                prev.map((user) =>
                    user.aup_id === id ? { ...user, [field]: value } : user
                )
            );
        }
    };

    const handleSave = async (id) => {
        const userConfig = usersConfig.find((user) => user.aup_id === id);
        try {
            const token = localStorage.getItem('token');
            if (!token) throw new Error('No token found, please login');
            const response = await fetch(`${config.API_URL}/update-user-config`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(userConfig),
            });
            if (!response.ok) throw new Error('Error updating user config');
            toast.success('Successfully Updated');
        } catch (error) {
            console.error('Error:', error);
            toast.error('Failed to Update');
        }
    };

    const handleAdd = async () => {
        if (!newConfig || !selectedUser) {
            toast.error('Please fill in all fields');
            return;
        }
        const configToAdd = { ...newConfig, user_name: selectedUser };
        try {
            const token = localStorage.getItem('token');
            if (!token) throw new Error('No token found, please login');
            const response = await fetch(`${config.API_URL}/add-user-config`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(configToAdd),
            });
            if (!response.ok) throw new Error('Error adding new config');
            const addedConfig = await response.json();
            setUsersConfig((prev) => [...prev, addedConfig]);
            setNewConfig(null);
            toast.success('Successfully Added');
        } catch (error) {
            console.error('Error:', error);
            toast.error(`Failed to Add: ${error.message}`);
        }
    };

    const handleRemoveNewRow = () => {
        setNewConfig(null);
    };

    const addNewRow = () => {
        setNewConfig({
            aup_id: 'new',
            is_enabled: 0,
            stop_algo: 0,
            strategy: '',
            lot_qty: '',
            profit_limit_per_lot: '',
            stop_loss_limit_per_lot: '',
            scrip_name: '',
        });
    };

    const filteredConfig = usersConfig.filter((user) => user.user_name === selectedUser);

    return (
        <div className="user-config-modal">
            <div className="modal-content">
                <h2>User Config</h2>
                {isAdmin && (
                    <label>
                        User Name:
                        <select
                            value={selectedUser}
                            onChange={(e) => setSelectedUser(e.target.value)}
                        >
                            <option value="">Select User</option>
                            {Array.from(new Set(usersConfig.map((user) => user.user_name))).map(
                                (uniqueUserName, index) => (
                                    <option key={index} value={uniqueUserName}>
                                        {uniqueUserName}
                                    </option>
                                )
                            )}
                        </select>
                    </label>
                )}
                {filteredConfig.length > 0 || newConfig ? (
                    <table className="config-table">
                        <thead>
                            <tr>
                                <th>AUP ID</th>
                                <th>Is Enabled</th>
                                <th>Stop Algo</th>
                                <th>Strategy</th>
                                <th>Lot Qty</th>
                                <th>Profit Gain</th>
                                <th>Profit Loss</th>
                                <th>Profit Limit</th>
                                <th>Stop Loss Limit</th>
                                <th>Scrip Name</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredConfig.map((user) => (
                                <tr key={user.aup_id}>
                                    <td>{user.aup_id}</td>
                                    <td>
                                        <input
                                            type="checkbox"
                                            checked={!!user.is_enabled}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e.target.checked ? 1 : 0,
                                                    'is_enabled',
                                                    user.aup_id
                                                )
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="checkbox"
                                            checked={!!user.stop_algo}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e.target.checked ? 1 : 0,
                                                    'stop_algo',
                                                    user.aup_id
                                                )
                                            }
                                        />
                                    </td>
                                    <td>
                                        <span>{user.strategy}</span>
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            value={user.lot_qty}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e.target.value,
                                                    'lot_qty',
                                                    user.aup_id
                                                )
                                            }
                                        />
                                    </td>
                                    <td>
                                        <span>{user.profit_gain}</span>
                                    </td>
                                    <td>
                                        <span>{user.profit_loss}</span>
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            value={user.profit_limit_per_lot}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e.target.value,
                                                    'profit_limit_per_lot',
                                                    user.aup_id
                                                )
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            value={user.stop_loss_limit_per_lot}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e.target.value,
                                                    'stop_loss_limit_per_lot',
                                                    user.aup_id
                                                )
                                            }
                                        />
                                    </td>
                                    <td>
                                        <span>{user.scrip_name}</span>
                                    </td>
                                    <td>
                                        <button onClick={() => handleSave(user.aup_id)}>
                                            Save
                                        </button>
                                    </td>
                                </tr>
                            ))}
                            {newConfig && (
                                <tr>
                                    <td>New</td>
                                    <td>
                                        <input
                                            type="checkbox"
                                            checked={!!newConfig.is_enabled}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e.target.checked ? 1 : 0,
                                                    'is_enabled',
                                                    'new'
                                                )
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="checkbox"
                                            checked={!!newConfig.stop_algo}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e.target.checked ? 1 : 0,
                                                    'stop_algo',
                                                    'new'
                                                )
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            value={newConfig.strategy}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e.target.value,
                                                    'strategy',
                                                    'new'
                                                )
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            value={newConfig.lot_qty}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e.target.value,
                                                    'lot_qty',
                                                    'new'
                                                )
                                            }
                                        />
                                    </td>
                                    <td>
                                        <span>N/A</span>
                                    </td>
                                    <td>
                                        <span>N/A</span>
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            value={newConfig.profit_limit_per_lot}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e.target.value,
                                                    'profit_limit_per_lot',
                                                    'new'
                                                )
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            value={newConfig.stop_loss_limit_per_lot}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e.target.value,
                                                    'stop_loss_limit_per_lot',
                                                    'new'
                                                )
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            value={newConfig.scrip_name}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e.target.value,
                                                    'scrip_name',
                                                    'new'
                                                )
                                            }
                                        />
                                    </td>
                                    <td>
                                        <button onClick={handleAdd}>Add</button>
                                        <button onClick={handleRemoveNewRow}>Remove</button>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                ) : (
                    <p>No Config</p>
                )}
                <div className="modal-buttons">
                    {isAdmin && selectedUser && !newConfig && (
                        <button onClick={addNewRow}>Add Config</button>
                    )}
                    <button className="close-button" onClick={onClose}>
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
}

export default UserConfigModal;