import React, { useState, useEffect } from 'react';
import './AddExpiryDateModal.css';
import config from '../config/config';
import { toast } from 'react-toastify';

function AddExpiryDateModal({ onClose }) {
    const [scrips, setScrips] = useState([]);
    const [scripName, setScripName] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [formattedExpDate, setFormattedExpDate] = useState('');
    const [kiteExpiry, setKiteExpiry] = useState('');
    const [shoonyaExpiry, setShoonyaExpiry] = useState('');
    const [fivepaisaExpiry, setFivepaisaExpiry] = useState('');
    const [angelExpiry, setAngelExpiry] = useState('');
    const [monthEndExpiry, setMonthEndExpiry] = useState(false);

    const formatExpiryDates = (date) => {
        if (!date) return {};
        const formattedDate = new Date(date);
        
        // Format for kite_expiry and fivepaisa_expiry: YYMMDD
        const yy = String(formattedDate.getFullYear()).slice(-2);
        const mm = String(formattedDate.getMonth() + 1).padStart(2, '0');
        const dd = String(formattedDate.getDate()).padStart(2, '0');
        const yyMMdd = `${yy}${mm}${dd}`;
        
        // Format for shoonya_expiry: DDMMMYY (e.g., 26DEC24)
        const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
        const ddMMMYY = `${dd}${months[formattedDate.getMonth()]}${yy}`;
        const ddMMM = `${dd}${months[formattedDate.getMonth()]}`;
        
        // Format for angel_expiry: YYYY-MM-DD
        const yyyyMMdd = formattedDate.toISOString().split('T')[0];

        if (monthEndExpiry) {
            return {
                kiteExpiry: ddMMM,
                shoonyaExpiry: ddMMMYY,
                fivepaisaExpiry: yyMMdd,
                angelExpiry: yyyyMMdd,
            };
        }

        return {
            kiteExpiry: yyMMdd,
            shoonyaExpiry: ddMMMYY,
            fivepaisaExpiry: yyMMdd,
            angelExpiry: yyyyMMdd,
        };
    };

    const handleExpiryDateChange = (date) => {
        setExpiryDate(date);

        // Format the exp_date to include the required time and suffix
        const isoFormattedDate = `${date}T07:00:00.000Z`;
        setFormattedExpDate(isoFormattedDate);

        // Format other expiry fields
        const { kiteExpiry, shoonyaExpiry, fivepaisaExpiry, angelExpiry } = formatExpiryDates(date);
        setKiteExpiry(kiteExpiry);
        setShoonyaExpiry(shoonyaExpiry);
        setFivepaisaExpiry(fivepaisaExpiry);
        setAngelExpiry(angelExpiry);
    };

    const fetchScrips = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No token found, please login');
            }
            const response = await fetch(`${config.API_URL}/get-scrips`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            if (!response.ok) {
                throw new Error('Error fetching scrips');
            }
            const data = await response.json();
            setScrips(data);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        fetchScrips();
    }, []);

    const handleSave = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No token found, please login');
            }

            const toDateTime = toDate ? `${toDate}T23:59:59` : null;
    
            const response = await fetch(`${config.API_URL}/add-algoexpdate`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    scrip_name: scripName,
                    from_date: fromDate,
                    to_date: toDateTime,
                    exp_date: formattedExpDate,
                    kite_expiry: kiteExpiry,
                    shoonya_expiry: shoonyaExpiry,
                    fivepaisa_expiry: fivepaisaExpiry,
                    angel_expiry: angelExpiry,
                }),
            });
    
            if (!response.ok) {
                // Attempt to parse the error message from the API response
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to save expiry date');
            }
    
            const data = await response.json();
            console.log('Save successful:', data);
            onClose();
        } catch (error) {
            console.error('Error:', error);
            toast.error(`${error.message}`);
        }
    };    

    return (
        <div className="add-expiry-date-modal">
            <div className="modal-content">
                <h2>Add Expiry Date</h2>
                <label>
                    Scrip Name:
                    <select value={scripName} onChange={(e) => setScripName(e.target.value)}>
                        <option value="">Select Scrip</option>
                        {scrips.map(([id, name]) => (
                            <option key={id} value={name}>
                                {name}
                            </option>
                        ))}
                    </select>
                </label>
                <label>
                    From Date
                    <input
                        type="date"
                        value={fromDate}
                        onChange={(e) => setFromDate(e.target.value)}
                    />
                </label>
                <label>
                    To Date
                    <input
                        type="date"
                        value={toDate}
                        onChange={(e) => setToDate(e.target.value)}
                    />
                </label>
                <label>
                    Month End Expiry (If applicable check before selecting expiry date)
                    <input
                        type='checkbox'
                        checked={monthEndExpiry}
                        onChange={(e) => setMonthEndExpiry(e.target.checked)}
                    />
                </label>
                <label>
                    Expiry Date
                    <input
                        type="date"
                        value={expiryDate}
                        onChange={(e) => handleExpiryDateChange(e.target.value)}
                    />
                </label>
                <label>
                    Formatted Expiry Date
                    <input
                        type="text"
                        value={formattedExpDate}
                        onChange={(e) => setFormattedExpDate(e.target.value)}
                    />
                </label>
                <label>
                    Kite Expiry
                    <input
                        type="text"
                        value={kiteExpiry}
                        onChange={(e) => setKiteExpiry(e.target.value)}
                    />
                </label>
                <label>
                    Shoonya Expiry
                    <input
                        type="text"
                        value={shoonyaExpiry}
                        onChange={(e) => setShoonyaExpiry(e.target.value)}
                    />
                </label>
                <label>
                    Fivepaisa Expiry
                    <input
                        type="text"
                        value={fivepaisaExpiry}
                        onChange={(e) => setFivepaisaExpiry(e.target.value)}
                    />
                </label>
                <label>
                    Angel Expiry
                    <input
                        type="text"
                        value={angelExpiry}
                        onChange={(e) => setAngelExpiry(e.target.value)}
                    />
                </label>
                <div className="modal-buttons">
                    <button className="save-button" onClick={handleSave}>Save</button>
                    <button className="close-button" onClick={onClose}>Cancel</button>
                </div>
            </div>
        </div>
    );
}

export default AddExpiryDateModal;