import React, { useState, useEffect } from "react";
import "./EquityWynAllCalls.css";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../Footer";

import config from '../../config/config';

function EquityWynAllCalls() {
    const location = useLocation();
    const { userId } = location.state || {};
    const [stocks, setStocks] = useState([]);
    const [filteredStocks, setFilteredStocks] = useState([]);
    const [selectedStock, setSelectedStock] = useState('');
    const [tickerCodes, setTickerCodes] = useState([]);
    const [stocksCurrentPrice, setStocksCurrentPrice] = useState([]);
    const [fromDate, setFromDate] = useState('2024-05-01');
    const [toDate, setToDate] = useState(new Date().toISOString().split('T')[0]);
    const [statusFilter, setStatusFilter] = useState('');
    const [priceFilter, setPriceFilter] = useState('');

    const [sortedColumn, setSortedColumn] = useState(null);
    const [sortOrder, setSortOrder] = useState("asc");

    const navigate = useNavigate();


  const width = window.innerWidth;

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("No token found, please login");
    }
    fetch(`${config.API_URL}/get-equity-stocks/${userId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error fetching equities");
        }
        return response.json();
      })
      .then((data) => {
        setStocks(data);
        setFilteredStocks(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [userId]);

  useEffect(() => {
    const applyFilters = () => {
      let filtered = stocks;

      // Date filter
      filtered = filtered.filter((stock) => {
        const callDate = new Date(stock.call_date);
        return callDate >= new Date(fromDate) && callDate <= new Date(toDate);
      });

      // Stock filter
      if (selectedStock) {
        filtered = filtered.filter(
          (stock) => stock.stock_name === selectedStock
        );
      }

      // Status filter
      if (statusFilter) {
        filtered = filtered.filter(
          (stock) => stock.equity_status === statusFilter
        );
      }

      // Price filter
      if (priceFilter) {
        filtered = filtered.filter((stock) => {
          const price = stock.call_price;
          switch (priceFilter) {
            case "0-100":
              return price >= 0 && price <= 100;
            case "101-200":
              return price > 100 && price <= 200;
            case "201-500":
              return price > 200 && price <= 500;
            case "501-1000":
              return price > 500 && price <= 1000;
            case "1000+":
              return price > 1000;
            default:
              return true;
          }
        });
      }

      // Sort by equity_status, placing 'OPEN' cards first
      filtered = filtered.sort((a, b) => {
        if (a.equity_status === "OPEN" && b.equity_status !== "OPEN") {
          return -1; // 'OPEN' comes first
        } else if (a.equity_status !== "OPEN" && b.equity_status === "OPEN") {
          return 1; // 'CLOSED' comes after
        }
        return 0; // Maintain order for the same status
      });

      setFilteredStocks(filtered);
    };

    applyFilters();
  }, [stocks, fromDate, toDate, selectedStock, statusFilter, priceFilter]);

  useEffect(() => {
    if (stocks && stocks.length > 0) {
      const tickerCodes = stocks.map((equity) => equity.ticker_code);
      setTickerCodes(tickerCodes);
    }
  }, [stocks]);

  useEffect(() => {
    if (tickerCodes && tickerCodes.length > 0) {
      fetchStocksCMP(tickerCodes);
    }
  }, [tickerCodes]);

  const fetchStocksCMP = async (tickerCodes) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("No token found, please login");
      }
      const response = await fetch(`${config.API_URL}/get-equity-stocks-cmp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ ticker_codes: tickerCodes }),
      });
      if (!response.ok) {
        throw new Error(`Error fetching stocks: ${response.statusText}`);
      }
      const data = await response.json();
      setStocksCurrentPrice(data);
    } catch (error) {
      console.error("Error in fetchStocksCMP:", error);
    }
  };

  const formatData = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = String(date.getUTCFullYear()).slice(-2);
    return `${day}/${month}/${year}`;
  };

  const handleCardClick = (stock_id) => {
    navigate("/equitywyn/stock-details", { state: { stock_id } });
  };

  const handleClearFilter = () => {
    setFromDate("2024-05-01");
    setToDate(new Date().toISOString().split("T")[0]);
    setSelectedStock("");
    setStatusFilter("");
    setPriceFilter("");
  };

  const handleSort = (column) => {
    const order = sortedColumn === column && sortOrder === "asc" ? "desc" : "asc";
    setSortedColumn(column);
    setSortOrder(order);
  
    const sortedData = [...filteredStocks].sort((a, b) => {
      let valueA, valueB;
    
      if (column === "expected_profit") {
        valueA = parseFloat(
          (((a.target_price - a.call_price) / a.call_price) * 100).toFixed(2)
        );
        valueB = parseFloat(
          (((b.target_price - b.call_price) / b.call_price) * 100).toFixed(2)
        );
      } else if (column === "call_date") {
        valueA = new Date(a.call_date);
        valueB = new Date(b.call_date);
      } else if (column === "time_frame") {
        const getMonths = (timeFrame) => {
          if (!timeFrame) return 0;
          timeFrame = timeFrame.toLowerCase();
          if (timeFrame.includes("year")) return parseInt(timeFrame) * 12;
          if (timeFrame.includes("month")) return parseInt(timeFrame);
          return 0;
        };
        valueA = getMonths(a.time_frame);
        valueB = getMonths(b.time_frame);
      } else if (column === "pnl") {
        valueA = parseFloat(
          (((stocksCurrentPrice[a.ticker_code] || 0) - a.call_price) /
            a.call_price) *
            100
        );
        valueB = parseFloat(
          (((stocksCurrentPrice[b.ticker_code] || 0) - b.call_price) /
            b.call_price) *
            100
        );
      } else if (column === "cmp") {
        valueA = parseFloat(stocksCurrentPrice[a.ticker_code]) || -Infinity;
        valueB = parseFloat(stocksCurrentPrice[b.ticker_code]) || -Infinity;
      } else if (column === "stop_loss") {
        valueA = parseFloat(a.stop_loss) || 0;
        valueB = parseFloat(b.stop_loss) || 0;
      } else {
        valueA = a[column];
        valueB = b[column];
    
        if (typeof valueA === "string" && typeof valueB === "string") {
          valueA = valueA.toLowerCase();
          valueB = valueB.toLowerCase();
        }
      }
    
      if (order === "asc") {
        return valueA > valueB ? 1 : -1;
      } else {
        return valueA < valueB ? 1 : -1;
      }
    });
    
    setFilteredStocks(sortedData);
    
  };

  if (!userId) {
    return (
      <div>
        <h1>Not Authorised</h1>
      </div>
    );
  }

  return (
    <div className="equity-wyn-all-calls-container" style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <main className="equity-wyn-container">
        <div className="action-buttons-equitywyn-all-calls">
          <button
            className="back-button-all-calls"
            onClick={() => navigate(-1, { state: { userId } })}
          >
            Back
          </button>
        </div>
        <h2
          className="equitywyn-title"
          style={{ marginTop: "20px", color: "#ff593f", fontWeight: "bold" }}
        >
          EquityWyn - All Calls
        </h2>
        <div className="filters-all-calls">
          <div className="date-selection">
            <label>
              From Date:
              <input
                type="date"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
              />
            </label>
            <label>
              To Date:
              <input
                type="date"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
              />
            </label>
          </div>
            <div className="selection-filters-all-calls">
              <div className="dropdown-selection">
                <label>
                  Select Stock
                  <select
                    value={selectedStock}
                    onChange={(e) => setSelectedStock(e.target.value)}
                  >
                    <option value="">ALL</option>
                    {stocks
                      .slice() // Create a shallow copy of stocks to avoid mutating state
                      .sort((a, b) => a.stock_name.localeCompare(b.stock_name))
                      .map((stock) => (
                        <option key={stock.id} value={stock.stock_name}>
                          {stock.stock_name}
                        </option>
                      ))}
                  </select>
                </label>
              </div>
              <div className="dropdown-selection-filter">
                <label>
                  Status Filter
                  <select
                    value={statusFilter}
                    onChange={(e) => setStatusFilter(e.target.value)}
                  >
                    <option value="">All</option>
                    <option value="BOOKLOSS">Book Loss</option>
                    <option value="BOOKPROFIT">Book Profit</option>
                    <option value="CLOSED">Closed</option>
                    <option value="OPEN">Open</option>
                  </select>
                </label>
              </div>
              <div className="dropdown-selection-filter">
                <label>
                  Price Filter
                  <select
                    value={priceFilter}
                    onChange={(e) => setPriceFilter(e.target.value)}
                  >
                    <option value="">All</option>
                    <option value="0-100">0-100</option>
                    <option value="101-200">101-200</option>
                    <option value="201-500">201-500</option>
                    <option value="501-1000">501-1000</option>
                    <option value="1000+">1000+</option>
                  </select>
                </label>
              </div>
              <button className="clear-filter-all-calls" onClick={handleClearFilter}>
                Clear Filters
              </button>
            </div>
        </div>
        <div className="all-calls-table">
          <table className="table table-striped">
          <thead>
            <tr>
              <th onClick={() => handleSort("stock_name")} style={{ cursor: "pointer" }}>
                Stock Name {sortedColumn === "stock_name" && (sortOrder === "asc" ? "↑" : "↓")}
              </th>
              <th onClick={() => handleSort("cmp")} style={{ cursor: "pointer" }}>
                CMP {sortedColumn === "cmp" && (sortOrder === "asc" ? "↑" : "↓")}
              </th>
              {width > 578 && (
                <th onClick={() => handleSort("target_price")} style={{ cursor: "pointer" }}>
                  Target {sortedColumn === "target_price" && (sortOrder === "asc" ? "↑" : "↓")}
                </th>
              )}
              {width > 578 && (
                <th onClick={() => handleSort("stop_loss")} style={{ cursor: "pointer" }}>
                  SL {sortedColumn === "stop_loss" && (sortOrder === "asc" ? "↑" : "↓")}
                </th>
              )}
              {width > 578 && (
                <th onClick={() => handleSort("expected_profit")} style={{ cursor: "pointer" }}>
                  Exp. Profit {sortedColumn === "expected_profit" && (sortOrder === "asc" ? "↑" : "↓")}
                </th>
              )}
              <th onClick={() => handleSort("call_date")} style={{ cursor: "pointer" }}>
                Call Date {sortedColumn === "call_date" && (sortOrder === "asc" ? "↑" : "↓")}
              </th>
              {width > 578 && (
                <th onClick={() => handleSort("time_frame")} style={{ cursor: "pointer" }}>
                  Horizon {sortedColumn === "time_frame" && (sortOrder === "asc" ? "↑" : "↓")}
                </th>
              )}
              <th onClick={() => handleSort("equity_status")} style={{ cursor: "pointer" }}>
                Status {sortedColumn === "equity_status" && (sortOrder === "asc" ? "↑" : "↓")}
              </th>
              {width > 578 && (
                <th onClick={() => handleSort("pnl")} style={{ cursor: "pointer" }}>
                  PnL {sortedColumn === "pnl" && (sortOrder === "asc" ? "↑" : "↓")}
                </th>
              )}
            </tr>
          </thead>
            <tbody>
              {filteredStocks
                .filter((call) => call.equity_status !== "WATCH")
                .map((call, index) => {
                  const currentPrice =
                    stocksCurrentPrice[call.ticker_code] || "N/A";

                  return (
                    <tr key={index}>
                      <td>
                        <span
                          className="clickable-stock-name"
                          onClick={() => handleCardClick(call.id)}
                          style={{
                            cursor: "pointer",
                            color: "#2880eb",
                            textDecoration: "underline",
                          }} // optional inline styling
                        >
                          {call.stock_name}
                        </span>
                      </td>
                      <td>{currentPrice}</td>
                      {width > 578 && <td>{call.target_price}</td>}
                      {width > 578 && <td>{call.stop_loss}</td>}
                      {width > 578 && (
                        <td>
                          {(
                            ((call.target_price - call.call_price) /
                              call.call_price) *
                            100
                          ).toFixed(2)}
                          %
                        </td>
                      )}
                      <td>{formatData(call.call_date)}</td>
                      {width > 578 && <td>{call.time_frame}</td>}
                      <td>{call.equity_status}</td>
                      {width > 578 && <td>{((currentPrice-call.call_price)/call.call_price*100).toFixed(2)}%</td>}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default EquityWynAllCalls;
