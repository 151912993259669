import React from 'react';
import './HighMomentumStockDetails.css';

function HighMomentumStockDetails({ stockDetails, stockCode, onClose }) {
    return (
        <div className="popup-HMSD">
            <div className="popup-content-HMSD">
                <h3><a href={`https://secure.icicidirect.com/trading/getquote/${stockCode}`} target="_blank" rel="noopener noreferrer" style={{ color: '#4382ff' }} >{stockCode} (Click to open)</a></h3>
                <table>
                    <thead>
                        <tr>
                            <th>Time</th>
                            <th>Price</th>
                            <th>Daily Return</th>
                            <th>OBV</th>
                            <th>Momentum</th>
                            <th>Buy Signals</th>
                            <th>Sell Signals</th>
                        </tr>
                    </thead>
                    <tbody>
                        {stockDetails.map((stock, index) => (
                            <tr key={index}>
                                <td>{stock.time}</td>
                                <td>{stock.price}</td>
                                <td>{stock.Daily_Return !== null ? stock.Daily_Return : 'N/A'}</td>
                                <td>{stock.OBV}</td>
                                <td>{stock.Momentum !== null ? stock.Momentum : 'N/A'}</td>
                                <td>{stock.Buy_Signals !== null ? stock.Buy_Signals : 'N/A'}</td>
                                <td>{stock.Sell_Signals !== null ? stock.Sell_Signals : 'N/A'}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <button onClick={onClose}>Close</button>
            </div>
        </div>
    );
}

export default HighMomentumStockDetails;