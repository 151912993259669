import React, { useEffect, useState, useMemo } from 'react';
import config from '../../config/config';
import { useNavigate } from 'react-router-dom';
import './StocksDashboard.css';

function StocksDashboard() {
    const navigate = useNavigate();
    const [dataRecieved, setDataRecieved] = useState(false);
    const [stocks, setStocks] = React.useState([]);
    const [sortOrder, setsortOrder] = useState({ column: null, direction: 'ascending' });


    const formatDate = (dateString) => {
        if (!dateString) return '-';
        const date = new Date(dateString);
        const day = String(date.getUTCDate()).padStart(2, '0');
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const year = String(date.getUTCFullYear()).slice(-2);
        return `${day}/${month}/${year}`;
    };

    const formatNumber = (number) => {
        if (number == null) return '-';
        return new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(number);
    };

    const handleCardClick = (stock_id) => {
        navigate("/equitywyn/stock-details", { state: { stock_id } });
    };

    const fetchStockDetails = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No token found, please login');
            }
            const response = await fetch(`${config.API_URL}/get-equity-pnl`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            if (!response.ok) {
                throw new Error('Error fetching stocks');
            }
            const data = await response.json();
            setStocks(data);
            setDataRecieved(true);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        fetchStockDetails();
    }, []);

    const sortStocks = (column) => {
        let direction = 'ascending';
        if (sortOrder.column === column && sortOrder.direction === 'ascending') {
            direction = 'descending';
        }
        setsortOrder({ column, direction });  // Set the sort configuration when a column is clicked
    };

    const sortedStocks = useMemo(() => {
        if (!sortOrder.column) return stocks;

        const sorted = [...stocks].sort((a, b) => {
            const aValue = a[sortOrder.column];
            const bValue = b[sortOrder.column];

            if (aValue == null) return 1;
            if (bValue == null) return -1;

            // Handle date columns with custom sorting
            if (sortOrder.column === 'call_date' || sortOrder.column === 'sell_date') {
                const aDate = new Date(aValue.split('/').reverse().join('-'));
                const bDate = new Date(bValue.split('/').reverse().join('-'));
                return sortOrder.direction === 'ascending' ? aDate - bDate : bDate - aDate;
            }

            // Handle numeric columns like call_price, sell_price, etc.
            if (!isNaN(aValue) && !isNaN(bValue)) {
                return sortOrder.direction === 'ascending'
                    ? parseFloat(aValue) - parseFloat(bValue)
                    : parseFloat(bValue) - parseFloat(aValue);
            }

            // Default string comparison
            if (aValue < bValue) return sortOrder.direction === 'ascending' ? -1 : 1;
            if (aValue > bValue) return sortOrder.direction === 'ascending' ? 1 : -1;
            return 0;
        });

        return sorted;
    }, [stocks, sortOrder]);
   

    const totalInvestment = stocks.reduce((sum, stock) => sum + (stock.investment_amount || 0), 0);
    const totalRealisedProfit = stocks.reduce((sum, stock) => sum + (stock.realised_profit || 0), 0);
    const totalUnrealisedProfit = stocks.reduce((sum, stock) => sum + (stock.unrealised_profit || 0), 0);
    const overallPnL = totalRealisedProfit + totalUnrealisedProfit;

    const getColorClass = (value) => (value >= 0 ? 'text-success' : 'text-danger');

    return (
        <div className="container mt-4">
            <button className="back-button-equitywyn" onClick={() => navigate(-1)}>Back</button>
            <h1>Stocks Scoreboard</h1>
            <div className="scoreboard-table">
                <table className="table table table-hover">
                    <thead className="table-dark">
                        <tr>
                            <th onClick={() => sortStocks('id')} style={{ cursor: "pointer" }}>ID</th>
                            <th onClick={() => sortStocks('stock_name')} style={{ cursor: "pointer" }}>Stock Name </th>
                            <th onClick={() => sortStocks('call_date')} style={{ cursor: "pointer" }}>Call Date</th>
                            <th onClick={() => sortStocks('call_price')} style={{ cursor: "pointer" }}>Call Price</th>
                            <th onClick={() => sortStocks('sell_date')} style={{ cursor: "pointer" }}>Sell Date</th>
                            <th onClick={() => sortStocks('sell_price')} style={{ cursor: "pointer" }}>Sell Price</th>
                            <th onClick={() => sortStocks('current_price')} style={{ cursor: "pointer" }}>Current Price</th>
                            <th onClick={() => sortStocks('target_price')} style={{ cursor: "pointer" }}>Target Price</th>
                            <th onClick={() => sortStocks('investment_amount')} style={{ cursor: "pointer" }}>Investment Amount</th>
                            <th onClick={() => sortStocks('realised_profit')} style={{ cursor: "pointer" }}>Realised Profit</th>
                            <th onClick={() => sortStocks('unrealised_profit')} style={{ cursor: "pointer" }}>Unrealised Profit</th>
                            <th onClick={() => sortStocks('equity_status')} style={{ cursor: "pointer" }}>Equity Status</th>
                            <th onClick={() => sortStocks('pnl_p')} style={{ cursor: "pointer" }}>PnL (%)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedStocks.length > 0 ? (
                            sortedStocks.map((stock, index) => (
                                <tr
                                    key={index}
                                    className={
                                        stock.equity_status === 'BOOKLOSS'
                                            ? 'table-dark'
                                            : stock.equity_status === 'BOOKPROFIT'
                                                ? 'table-success'
                                                : stock.stop_loss > stock.current_price
                                                    ? 'table-danger'
                                                    : stock.pnl_p <= -15
                                                        ? 'table-warning'
                                                        : ''
                                    }
                                >
                                    <td>{stock.id || '-'}</td>
                                    <td>
                                        <span
                                            className="clickable-stock-name"
                                            onClick={() => handleCardClick(stock.id)}
                                            style={{
                                                cursor: "pointer",
                                                color: "#2880eb",
                                                textDecoration: "underline",
                                            }}
                                        >
                                            {stock.stock_name}
                                        </span>
                                    </td>
                                    <td>{formatDate(stock.call_date)}</td>
                                    <td>{stock.call_price || '-'}</td>
                                    <td>{formatDate(stock.sell_date)}</td>
                                    <td>{stock.sell_price || '-'}</td>
                                    <td>{stock.current_price || '-'}</td>
                                    <td>{stock.target_price || '-'}</td>
                                    <td>{formatNumber(stock.investment_amount)}</td>
                                    <td className={getColorClass(stock.realised_profit)}>{formatNumber(stock.realised_profit)}</td>
                                    <td className={getColorClass(stock.unrealised_profit)}>{formatNumber(stock.unrealised_profit)}</td>
                                    <td>{stock.equity_status || '-'}</td>
                                    <td className={getColorClass(stock.pnl_p)}>{formatNumber(stock.pnl_p) + '%'}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="13">Loading...</td>
                            </tr>
                        )}
                    </tbody>
                    {dataRecieved === true && (
                        <tfoot>
                            <tr className="table-info">
                                <td colSpan="8"><strong>Totals</strong></td>
                                <td><strong>{formatNumber(totalInvestment)}</strong></td>
                                <td className={getColorClass(totalRealisedProfit)}>
                                    <strong>
                                        {formatNumber(totalRealisedProfit)}
                                        <span> ({((totalRealisedProfit / totalInvestment) * 100).toFixed(2)}%)</span>
                                    </strong>
                                </td>
                                <td className={getColorClass(totalUnrealisedProfit)}>
                                    <strong>
                                        {formatNumber(totalUnrealisedProfit)}
                                        <span> ({((totalUnrealisedProfit / totalInvestment) * 100).toFixed(2)}%)</span>
                                    </strong>
                                </td>
                                <td colSpan="1"></td>
                                <td className={getColorClass(overallPnL)}>
                                    <strong>
                                        {formatNumber(overallPnL)}
                                        <span> ({((overallPnL / totalInvestment) * 100).toFixed(2)}%)</span>
                                    </strong>
                                </td>
                            </tr>
                        </tfoot>
                    )}
                </table>
            </div>
            <div className='color-code-container'>
                Color Codes >> Green: BOOKPROFIT || Grey: BOOKLOSS || Pink: Stop Loss Hit || Yellow: -15% Return
            </div>
        </div>
    );
}

export default StocksDashboard;