import React, { useState, useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';
import './AddStockModal.css';

import config from '../../config/config';

function AddStockModal({ onClose }) {
    const [stockName, setStockName] = useState('');
    const [symbol, setSymbol] = useState('');
    const [buyRange, setBuyRange] = useState('');
    const [callPrice, setCallPrice] = useState('');
    const [currentPrice, setCurrentPrice] = useState('');
    const [callDate, setCallDate] = useState('');
    const [stopLoss, setStopLoss] = useState('');
    const [targetPrice, setTargetPrice] = useState('');
    const [timeFrame, setTimeFrame] = useState('');
    const [equityStatus, setEquityStatus] = useState('');
    const [fundamental, setFundamental] = useState('');
    const [technical, setTechnical] = useState('');
    const [conclusion, setConclusion] = useState('');
    const [companies, setCompanies] = useState([]);
    // const [isSymbolDisabled, setIsSymbolDisabled] = useState(false);
    const [profiles, setProfiles] = useState([]);
    const [selectedProfile, setSelectedProfile] = useState([]);
    const [graphImage, setGraphImage] = useState('');
    
    useEffect(() => {
        fetchCompanies();
    }, []);

    const fetchCompanies = async () => {
        try {
            const token = localStorage.getItem('token');
                if (!token) {
                throw new Error('No token found, please login');
                }
            const response = await fetch(`${config.API_URL}/get-companies`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${token}`
                },
            });
            if (!response.ok) {
                throw new Error('Error fetching companies');
            }
            const data = await response.json();
            setCompanies(data);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        fetchProfiles();
    }, []);

    const fetchProfiles = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No token found, please login');
            }
            const response = await fetch(`${config.API_URL}/algoprofile`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            if (!response.ok) {
                throw new Error('Error fetching profiles');
            }
            const data = await response.json();
            setProfiles(data);
            console.log('Profiles:', data);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleCompanyChange = (newValue, actionMeta) => {
        if (actionMeta.action === 'select-option') {
            setStockName(newValue.label);
            setSymbol(newValue.value);
            // setIsSymbolDisabled(true);
        } else if (actionMeta.action === 'create-option') {
            setStockName(newValue.label);
            setSymbol('');
            // setIsSymbolDisabled(false);
        } else if (actionMeta.action === 'clear') {
            setStockName('');
            setSymbol('');
            // setIsSymbolDisabled(false);
        }
    };

    const handleSave = async () => {
        if (!stockName || !symbol) {
            alert('Please enter a stock name and symbol');
            return;
        }
        console.log('Selected Profiles:', selectedProfile);
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No token found, please login');
            }
    
            const formData = new FormData();
            formData.append("call_date", callDate);
            formData.append("stock_name", stockName);
            formData.append("ticker_code", symbol);
            formData.append("buy_range", buyRange);
            formData.append("call_price", callPrice);
            formData.append("current_price", currentPrice);
            formData.append("stop_loss", stopLoss);
            formData.append("target_price", targetPrice);
            formData.append("time_frame", timeFrame);
            formData.append("equity_status", equityStatus);
            formData.append("fundamental", fundamental);
            formData.append("technical", technical);
            formData.append("conclusion", conclusion);
    
            // Append selected profiles (array)
            selectedProfile.forEach((profile) => {
                formData.append("profiles", profile);
            });
    
            // Append the graph image (ensure graphImage is a valid File object)
            if (graphImage) {
                formData.append("graph_image", graphImage);
            } else {
                alert("Please upload a graph image");
                return;
            }
    
            const response = await fetch(`${config.API_URL}/add-equity-stock`, {
                method: 'POST',
                headers: {
                    "Authorization": `Bearer ${token}`, // Do not include 'Content-Type' when using FormData
                },
                body: formData,
            });
    
            if (!response.ok) {
                throw new Error('Failed to save stock');
            }
    
            const data = await response.json();
            console.log('Save successful:', data);
            onClose();
        } catch (error) {
            console.error('Error:', error);
        }
    };
    

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        setGraphImage(file);
    };

    return (
        <div className="add-stock-modal">
            <div className="modal-content">
                <h2>Add Stock</h2>
                <label>
                    Call Date
                    <input type="date" value={callDate} onChange={(e) => setCallDate(e.target.value)} />
                </label>
                Company Name
                <CreatableSelect
                    options={companies.map(company => ({ value: company.symbol, label: company.company }))}
                    onChange={handleCompanyChange}
                    placeholder="Select or Type Company"
                    isClearable
                    styles={{
                        control: (provided) => ({
                            ...provided,
                            width: '300px',
                            backgroundColor: '#2b2b2b',
                            color: '#f0f0f0',
                            borderColor: '#444',
                            boxShadow: 'none',
                        }),
                        placeholder: (provided) => ({
                            ...provided,
                            color: '#b6b6b6',
                        }),
                        input: (provided) => ({
                            ...provided,
                            color: '#f0f0f0',
                        }),
                        menu: (provided) => ({
                            ...provided,
                            backgroundColor: '#2b2b2b',
                            color: '#f0f0f0',
                        }),
                        singleValue: (provided) => ({
                            ...provided,
                            color: '#f0f0f0',
                        }),
                        option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isFocused ? '#444' : '#2b2b2b',
                            color: state.isFocused ? '#ffffff' : '#f0f0f0',
                        }),
                        dropdownIndicator: (provided) => ({
                            ...provided,
                            color: '#ffffff',
                        }),
                        indicatorSeparator: (provided) => ({
                            ...provided,
                            backgroundColor: '#ffffff',
                        }),
                        clearIndicator: (provided) => ({
                            ...provided,
                            color: '#ffffff',
                        }),
                        menuList: (provided) => ({
                            ...provided,
                            backgroundColor: '#2b2b2b',
                        }),
                    }}
                />
                <label>
                    Symbol (Add '.NS' or '.BO' in Symbol)
                    <input type="text" value={symbol} onChange={(e) => setSymbol(e.target.value)}/>
                </label>
                <label>
                    Buy Range
                    <input type="text" value={buyRange} onChange={(e) => setBuyRange(e.target.value)} />
                </label>
                <label>
                    Call Price
                    <input type="text" value={callPrice} onChange={(e) => setCallPrice(e.target.value)}/>
                </label>
                <label>
                    Current Price (CMP)
                    <input type="text" value={currentPrice} onChange={(e) => setCurrentPrice(e.target.value)}/>
                </label>
                <label>
                    Stop Loss
                    <input type="text" value={stopLoss} onChange={(e) => setStopLoss(e.target.value)} />
                </label>
                <label>
                    Target Price
                    <input type="text" value={targetPrice} onChange={(e) => setTargetPrice(e.target.value)} />
                </label>
                <label>
                    Time Frame
                    <input type="text" value={timeFrame} onChange={(e) => setTimeFrame(e.target.value)} />
                </label>
                <label>
                    Equity Status
                        <select value={equityStatus} onChange={(e) => setEquityStatus(e.target.value)}>
                            <option value="BOOKLOSS">Book Loss</option>
                            <option value="BOOKPROFIT">Book Profit</option>
                            <option value="CLOSED">Closed</option>
                            <option value="OPEN">Open</option>
                            <option value="WATCH">Watch List</option>
                        </select>
                </label>
                <div className='form-group'>
                    <label>Profiles</label>
                    {profiles.map((profile) => (
                        <div className='form-group-radio-item' key={profile.id}>
                        <input
                            type="checkbox"
                            value={profile.id}
                            checked={selectedProfile.includes(profile.id)}
                            onChange={() =>
                                setSelectedProfile((prev) =>
                                    prev.includes(profile.id)
                                        ? prev.filter((id) => id !== profile.id)
                                        : [...prev, profile.id]
                                )
                            }
                        />
                        {profile.profile_name}
                    </div>
                    ))}
                </div>
                <label>
                    Upload Graph Image
                    <input type="file" onChange={handleFileUpload} />
                </label>
                <label>
                    Fundamental Reasons
                    <input type="text" value={fundamental} onChange={(e) => setFundamental(e.target.value)} />
                </label>
                <label>
                    Technical Reasons
                    <input type="text" value={technical} onChange={(e) => setTechnical(e.target.value)} />
                </label>
                <label>
                    Conclusion
                    <input type="text" value={conclusion} onChange={(e) => setConclusion(e.target.value)} />
                </label>
                <div className="modal-buttons">
                    <button className="save-button" onClick={handleSave}>Save</button>
                    <button className="close-button" onClick={onClose}>Cancel</button>
                </div>
            </div>
        </div>
    );
}

export default AddStockModal;